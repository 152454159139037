
import * as Yup from 'yup';

export const mainFormSchema = Yup.object({
    firstname: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("First Name is required").matches(/^[A-Za-z .]*$/, 'Enter valid characters'),
    lastname: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("Last Name is required"),
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Mobile no. must be 10-digit')
        .required('Mobile Number is required'),
    comment: Yup.string().required("Please Enter Message"),
    termsandcondition: Yup.boolean().oneOf([true], "Privacy Policy and Terms of Use is required"),
});

export const familyHubEnquiryForm = Yup.object({
    firstname: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("First Name is required").matches(/^[A-Za-z .]*$/, 'Enter valid characters'),
    lastname: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("Last Name is required"),
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Mobile no. must be 10-digit')
        .required('Mobile Number is required'),
    comment: Yup.string().required("Please Enter Message"),
    termsandcondition: Yup.boolean().oneOf([true], "Privacy Policy and Terms of Use is required"),
})


export const getintouchFormSchema = Yup.object({
    firstName: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("First Name is required").matches(/^[A-Za-z .]*$/, 'Enter valid characters'),
    lastName: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("Last Name is required").matches(/^[A-Za-z .]*$/, 'Enter valid characters'),
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Mobile no. must be 10-digit')
        .required('Mobile Number is required'),
    // pincode: Yup.string().required("Pincode is required"),
    comment: Yup.string().required("Please Enter Message"),
})

export const contactformSchema = Yup.object({
    fullName: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("First Name is required").matches(/^[A-Za-z .]*$/, 'Enter valid characters'),
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Mobile no. must be 10-digit')
        .required('Mobile Number is required'),
    type: Yup.string().required("Business Vertical is required"),
    comment: Yup.string().required("Please Enter Message"),
})

export const careerformSchema = Yup.object({
    firstName: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("First Name is required").matches(/^[A-Za-z .]*$/, 'Enter valid characters'),
    lastName: Yup.string().min(2, 'Enter minimum 2 character').max(25, 'Enter maximum 25 character').required("Last Name is required").matches(/^[A-Za-z .]*$/, 'Enter valid characters'),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Mobile no. must be 10-digit')
        .required('Mobile Number is required'),
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    categoryType: Yup.string().required("Job Category is required"),
    // resumePdf: Yup.mixed()
    //     .nullable()
    //     .required('Please Select Your Resume')
    //     .test("FILE_SIZE", "Uploaded file is too big.",
    //         value => {
    //             return (value && value.size / (1024 * 1024) <= 2)
    //         })
    //     .test("FILE_FORMAT", "Uploaded file has unsupported format.",
    //         value => (value && ['pdf']?.includes(value?.name?.split(".")?.at(-1))))
})
