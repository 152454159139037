import React from 'react'
import { Link } from 'react-router-dom'
// import Header from './components/Header'
// import Footer from './components/Footer'

function Thankyoumain() {
    return (
        <>
        <div className='thanyoumainwrapper'>
        {/* <Header /> */}
        <div className='thanyoumain'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <img src='../images/au-logo-black.png' alt=''/>
                        <h1>Thank You!</h1>
                        <p> Your message was sent successfully. <br/> We will get back to you at the earlist!!</p>
                        <Link to="/" >Go Back To Home Page</Link>
                    </div>
                </div>
            </div>
        </div>
        </div>
        {/* <Footer /> */}
        </>
    )
}

export default Thankyoumain