import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Getintouch from './Getintouch'
import Metatag from './Metatag'

function Disclaimer() {
    return (
        <>
        <Metatag 
        link="disclaimer"
        title="AU Real Estate Disclaimer: Our Legal Information"
        description="Explore AU Real Estate's disclaimer for our legal information. Understand the terms and conditions regarding the use of our website and services"
        />
            <Header />
            <Getintouch />
            <div className='otherdiv'>
                <div className='container'>
                    <h1>Disclaimer</h1>
                </div>
            </div>
            <div className='breadcrum-div'>
                <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Disclaimer</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='privacy-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-10 col-lg-12'>
                            <p>Thank you for visiting our website </p>
                            <p> This disclaimer will be applicable to the website. By using or accessing the website you agree with the
                                disclaimer
                                without any qualification or limitation. The company reserves the right to add, alter or delete material
                                from
                                the website at any time and may, at any time, revise these terms without notifying you. You are bound by any
                                such
                                amendments and the company therefore advise that you periodically visit this page to review the current
                                terms. </p>
                            <p>The websites and all its content are provided on an "as is" and "as available" basis. No information given
                                under
                                this website creates a warranty or expand the scope of any warranty that cannot be claimed under the
                                applicable
                                laws. Your use of the website is solely at your own risk. This website is for guidance only. It does not
                                constitute
                                part of an offer or contract. Design & specifications are subject to change without any prior notice.
                                Computer
                                generated images are the artist's impression and are only an indicative of the actual designs. </p>
                            <p>The contents are being modified in terms of the stipulations / recommendations under the Real Estate
                                Regulation
                                Act, 2016 and rules made thereunder ("RERA") and accordingly may not be fully in line thereof as of date.
                                You are therefore required to verify all the details, including area, amenities, services, terms of sales
                                and
                                payments and other relevant terms independently with the company prior to concluding any decision for buying
                                any
                                unit(s) in any of the said projects. Till such time the details are fully updated, the said information will
                                not
                                be construed as an advertisement. To find out more about a project / development, please telephone our sales
                                centre or visit our sales office during office hours and speak to any of our sales staff.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Disclaimer