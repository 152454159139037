import Endpoints from "../Endpoints";
import { baseUrl } from "../ApiHelper";

export const fetchAarihantYear = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.arihantyear}`);
    return await data.json();
};

export const fetchArihantYearConstructionUpdates = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.arihantyearconstructionupdates}`);
    return await data.json();
};

export const fetchArihantConstructionUpdatesDataByMonth = async (value) => {
    const data = await fetch(`${baseUrl}/${Endpoints.arihantconstructionupdatesdatabymonth}${value}`);
    return await data.json();
};


export const fetchFamilyHubYear = async () => {
    const data = await fetch(`${baseUrl}/${Endpoints.familyhubyear}`);
    return await data.json();
};

export const fetchFamilyHubDataByMonth = async (value) => {
    const data = await fetch(`${baseUrl}/${Endpoints.familyhubdatabymonth}${value}`);
    return await data.json();
};


// export const fetchArihantConstructionUpdatesDataByMonth = async (value) => {
// console.log("valueeeeeeeeeeee",value);
//     const data = await fetch(`${baseUrl}/${Endpoints.arihantconstructionupdatesdatabymonth}+${value}`, {
//     method: 'POST', 
//     headers: {
//         'Content-Type': 'application/json'
//       },
//     mode: 'cors', 
//     body: value
  
//   });
  
//   console.log(" data12345", data)
//     return await data.json();
    
    
//   };