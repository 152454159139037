import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Getintouch from './Getintouch'
import Metatag from './Metatag'
import { Link } from 'react-router-dom'
import { BlogListDetails } from '../local-api/blogsListing'

function Blogs() {
    return (
        <>
            <Metatag
                link="blog"
                title="Examining India's Latest Real Estate Budget - Interim Budget 2024"
                description="Stay updated with the latest developments in India's real estate sector with insights on the Interim Budget 2024. Explore key changes and implications for the real estate project & their market. "
            />
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                {/* <video src='/websiteimages/banner-video.mp4' autoPlay loop muted /> */}
                <img src='/websiteimages/news-media-banner.png' alt='' />
                <div className='aurealestate-content'>
                    <h1>Blogs</h1>
                </div>
            </div>
            <div className='newsmain'>
                <div className='breadcrum-div breadcrumdiv'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Blogs</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2>{BlogListDetails?.heading}</h2>
                            <ul>
                                {BlogListDetails?.BlogList?.map((element, i) => {
                                    return <li>
                                        <Link rel='noreferrer' to={`${element?.link}`}>
                                            <div className='newsmediadiv'>
                                                <div className='newsmediadivleft'>
                                                    <img src={element?.image} alt='Latest Blog' />
                                                </div>
                                                <div className='newsmediadivright'>
                                                    <h3>{element?.title}</h3>
                                                    <p>{element?.desc}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Blogs
