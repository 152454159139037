import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Getintouch from './Getintouch'
import Metatag from './Metatag'

function Advisory() {
    return (
        <>
        <Metatag 
        link="advisory"
        title="Expert Real Estate Advisory Services | AU Real Estate"
        description="Unlock professional real estate advisory services at AU. Our experts offer comprehensive guidance for your property decisions and investments."
        />
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                <img src='/websiteimages/advisory-banner.jpg' alt='Advisory Banner' />
                <div className='aurealestate-content'>
                    <h1>Real Estate Advisory Services</h1>
                </div>
            </div>
            <div className='advisory-main'>
                <div className='breadcrum-div'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Advisory</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='service-div'>
                <div className='advisoryline'></div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h2>Overview</h2>
                                <p>AU Real Estate Advisory Services is your compass in the complex world of real estate. We specialize
                                     in Project Planning, meticulously crafting blueprints for your real estate endeavours. Our Market
                                      Study experts provide invaluable insights to guide your investments. With top-notch Project 
                                      Management, we ensure your vision becomes a reality, on time and within budget. Lastly, our 
                                      Project Marketing strategies ensure that your property reaches its audience, unlocking its full 
                                      potential. Trust us to navigate the real estate landscape with precision and success. <br />
                                Our in-depth analysis identifies market trends, demand, and competition, enabling informed investment 
                                decisions. 
                                </p>
                                <img src='/websiteimages/advisory-main-img.jpg' alt='Overview' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='advisory-productlist'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div className='advisory-productlist-div'>
                                    <img src='/websiteimages/advisory-land-img.jpg' alt='Land' />
                                    <h4>Land</h4>
                                    <p>We design comprehensive project blueprints, addressing every detail from concept to execution, ensuring your real estate endeavours align with your goals and market demands. 
                                    </p>
                                    {/* <h6>Learn More</h6> */}
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='advisory-productlist-div'>
                                    <img src='/websiteimages/advisory-project-img.jpg' alt='Project' />
                                    <h4>Projects</h4>
                                    <p>From the moment construction begins to the final delivery, our Project Management team takes 
                                        charge. We oversee every aspect, from scheduling and budget management to quality control. 
                                        Our experts ensure your project stays on course, meets your expectations, and is delivered on 
                                        time and within budget. We're your trusted partners in bringing your vision to life.
                                    </p>
                                    {/* <h6>Learn More</h6> */}
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='advisory-productlist-div'>
                                    <img src='/websiteimages/advisory-markting-img.jpg' alt='Markting' />
                                    <h4>Marketing</h4>
                                    <p>Project Marketing is the art of presenting your property to the world. 
                                        Our Project Marketing experts understand the nuances of both commercial and residential 
                                        markets. We craft and execute strategies that not only promote your property but also create 
                                        a demand that drives its success in the market. Our approach is dynamic and results-driven, 
                                        ensuring your project gets the attention it deserves. <br/>
                                        Our recent case study on Advisory Services: <a href='https://theresortdelhincr.com' rel="noreferrer" target='_blank'>https://theresortdelhincr.com/ </a>
                                    </p>
                                    {/* <h6>Learn More</h6> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='faq-main'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='faq-div'>
                                    <div className='faq-leftdiv'>
                                        <h3>Frequently Asked Questions</h3>
                                    </div>
                                    <div className='faq-rightdiv'>
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-related-map="map1" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Commitment
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Our commitment to delivery means adhering to timelines with unwavering dedication. <br />
                                                            Regarding specifications, we ensure every detail is met precisely. <br />
                                                            Quality is our uncompromising standard, guaranteeing excellence in every aspect of our
                                                            work</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-related-map="map2" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Customer Centricity
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Customer-centricity is the cornerstone of our approach, starting with personalized planning that aligns with clients' goals. <br />
                                                            Throughout the property's life, we offer support, transparency, and flexibility, ensuring their ownership experience is smooth, fulfilling, and tailored to their evolving needs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-related-map="map3" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Transparency
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Transparency is our guiding principle in every interaction.<br />
                                                            We openly share information, ensuring clarity, honesty, and trust. <br />
                                                            Whether in deals or day-to-day interactions, it fosters an environment of integrity, accountability, and strong, lasting partnerships</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    )
}

export default Advisory