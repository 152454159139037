import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Getintouch from './Getintouch';
import Metatag from './Metatag';

function Jointventures() {

    return (
        <>
        <Metatag 
        link="joint-ventures"
        title="AU Real Estate: Joint Ventures for Promising Projects"
        description="Explore AU Real Estate's joint venture opportunities for promising projects. Collaborate with us for successful and innovative real estate ventures."
        />
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                <img src='/websiteimages/joint-banner.jpg' alt='Joint Ventures banner' />
                <div className='aurealestate-content'>
                    <h1>AU Real Estate- Joint Venture</h1>
                </div>
            </div>
            <div className='advisory-main'>
                <div className='breadcrum-div'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Joint Ventures</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='service-div'>
                    <div className='advisoryline'></div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 jointventures'>
                                <h2>Overview</h2>
                                <p>AU Joint Ventures is your strategic partner in land development. We collaborate with landowners to 
                                    unlock the full potential of their property, maximizing its value through thoughtful and 
                                    profitable ventures. Our expertise and resources ensure a win-win partnership, transforming land 
                                    into thriving opportunities. <br/>
                                If you are contemplating developing your land, feel free to drop a line to us.
                                </p>
                                <img src='/websiteimages/jv-main-img.jpg' alt='Overview' />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='ourprojects'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='ourprojectstop'>
                                    <h2>Our Projects</h2>
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="All" aria-selected="true">All</button>
                                            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#delivered" type="button" role="tab" aria-controls="delivered" aria-selected="false">Delivered</button>
                                            <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#underconstruction" type="button" role="tab" aria-controls="underconstruction" aria-selected="false">Under Construction</button>
                                        </div>
                                    </nav>
                                </div>
                                <div className='ourprojectsbottom'>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='items'>
                                                            <div className='ourprojectscontent'>
                                                                <img src='/websiteimages/ourproject-img1.png' alt='' />
                                                                <div className='ourprojectscontentdiv'>
                                                                    <h4>Big Enclave</h4>
                                                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry.
                                                                    </h6>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                        since the 1500s, when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book.
                                                                    </p>
                                                                    <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='items'>
                                                            <div className='ourprojectscontent'>
                                                                <img src='/websiteimages/ourproject-img1.png' alt='' />
                                                                <div className='ourprojectscontentdiv'>
                                                                    <h4>Big Enclave</h4>
                                                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry.
                                                                    </h6>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                        since the 1500s, when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book.
                                                                    </p>
                                                                    <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='items'>
                                                            <div className='ourprojectscontent'>
                                                                <img src='/websiteimages/ourproject-img1.png' alt='' />
                                                                <div className='ourprojectscontentdiv'>
                                                                    <h4>Big Enclave</h4>
                                                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry.
                                                                    </h6>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                        industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                        since the 1500s, when an unknown printer took a galley of type and
                                                                        scrambled it to make a type specimen book.
                                                                    </p>
                                                                    <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="delivered" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='ourprojectscontent'>
                                                            <img src='/websiteimages/ourproject-img1.png' alt='' />
                                                            <div className='ourprojectscontentdiv'>
                                                                <h4>Big Enclave</h4>
                                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry.
                                                                </h6>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                    since the 1500s, when an unknown printer took a galley of type and
                                                                    scrambled it to make a type specimen book.
                                                                </p>
                                                                <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="underconstruction" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <OwlCarousel margin={15} autoplay={true} className="offers" {...options}>
                                                        <div className='ourprojectscontent'>
                                                            <img src='/websiteimages/ourproject-img1.png' alt='' />
                                                            <div className='ourprojectscontentdiv'>
                                                                <h4>Big Enclave</h4>
                                                                <h6>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry.
                                                                </h6>
                                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                                    industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                                    since the 1500s, when an unknown printer took a galley of type and
                                                                    scrambled it to make a type specimen book.
                                                                </p>
                                                                <a href='/' className='btn-style'>Learn More <img src='/websiteimages/right-arrow.png' alt='' /></a>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    )
}

export default Jointventures