const Endpoints={
   enquiryform :"enquiryform/add",
   familyhubenquiryform :"familyHubEnquiryForm/add",
   arihantyear :"constructionUpdatesYear",
   arihantyearconstructionupdates :"constructionUpdates",
   arihantconstructionupdatesdatabymonth :"getConstructionUpdatesDataByMonth?month=",
   familyhubyear : 'familyHubConstructionYear',
   familyhubdatabymonth : 'familyHubConstructionDataByMonth?month=',
   getintouch :'getInTouchForm/add',
   contactform :'contactUsForm/add',
   careerform : 'joinUsNowForm/add',
   homebanner :'homePageBanner',
   homePageGeneral : 'homePageGeneral',
   homeBusinessVerticles  :'homeBusinessVerticles',
   homeAUValues:'homeAUValues',
   homewhyChooseUs:'whyChooseUs'
}
export default Endpoints