import React from 'react'
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import { useEffect } from 'react'
import { useFormik } from 'formik';
import { fetchGetInTouch } from '../controler/get-in-touch/getintouch';
import { useNavigate} from 'react-router-dom';
import {getintouchFormSchema} from "./../schemas";

const initialValues = {
	firstName: "",
    lastName:"",
	email: "",
	phone: "",
    // pincode :"",
	comment: "",
};

function Getintouch() {
    // const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
		initialValues,
		validationSchema: getintouchFormSchema,
		onSubmit: async (values, action) => {
            console.log("Get in touch form validation ", values);
			const data = await fetchGetInTouch(values);
			console.log("Get in touch Form Handling ", data);
			action.resetForm();
			navigate('/thankyou')
		}

	})
    useEffect(() => {
        $(document).ready(function () {
            $('.gitintouch').click(function () {
                $('.sidebar-contact').toggleClass('active')
                $('.gitintouch').toggleClass('active')
            })
        })

        $(document).ready(function () {
            $('.enquire-product').click(function () {
                $('.sidebar-contact').toggleClass('active')
                $('.gitintouch').toggleClass('active')
            })
        })

        $(document).ready(function () {
            $(".toggleclose-icon").click(function () {
                $(".sidebar-contact").removeClass('active')
                $('.gitintouch').addClass('active')
            })
        })
    },[])
    return (
        <>
            <div className='sidebar-contact'>
                <div className='gitintouch quickconnecticonimage active'>
                        <p>Get In Touch</p>
                </div>
                <img src="/websiteimages/cross-icon.png" className="crossiconimage toggleclose-icon" alt="" width="20" height="20" />
                <div className='scroll'>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <input type='text' className='form-control' placeholder='First Name*'
                            name='firstName'
                            autoComplete='off'
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}/>
                            {errors.firstName && touched.firstName ? <p className="form-errors">{errors.firstName}</p> : null}
                        </div>
                        <div className='form-group'>
                            <input type='text' className='form-control' placeholder='Last Name*'
                            name='lastName'
                            autoComplete='off'
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}/>
                            {errors.lastName && touched.lastName ? <p className="form-errors">{errors.lastName}</p> : null}
                        </div>
                        <div className='form-group'>
                            <input type='text' className='form-control' placeholder='Email ID*'
                            name='email'
                            value={values.email}
                            autoComplete='off'
                            onChange={handleChange}
                            onBlur={handleBlur}/>
                            {errors.email && touched.email ? <p className='form-errors'>{errors.email}</p> : null}
                        </div>
                        <div className='form-group'>
                            <input type='text' maxLength={10} className='form-control' placeholder='Mobile No*'
                            name='phone'
                            value={values.phone}
                            autoComplete='off'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone ? <p className='form-errors'>{errors.phone}</p> : null}
                        </div>
                        {/* <div className='form-group'>
                            <input type='text' maxLength={6} className='form-control' placeholder='Pincode'
                            name='pincode'
                            value={values.pincode}
                            autoComplete='off'
                            onBlur={handleBlur}
                            onChange={handleChange}/>
                            {errors.pincode && touched.pincode ? <p className='form-errors'>{errors.pincode}</p> : null}
                        </div> */}
                        <div className='form-group'>
                            <textarea  className='form-control' placeholder='Write Your Comments*'
                            name='comment'
                            autoComplete='off'
                            value={values.comment}
                            onChange={handleChange}
                            onBlur={handleBlur}></textarea>
                            {errors.comment && touched.comment ? <p className='form-errors'>{errors.comment}</p> : null}
                        </div>
                        <button type='submit' className='btn-style'>Submit</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Getintouch