import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ReactPlayer from 'react-player'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Getintouch from './Getintouch';
import Metatag from './Metatag';

function Aboutus() {

    return (
        <>
        <Metatag link="aboutus" title="About AU Real Estate: Crafting Real Estate Excellence"
        description="Discover AU Real Estate's journey and commitment to excellence. Explore our mission, vision, and dedication to superior real estate services."
        />
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                <ReactPlayer width="100%" height="100%" playing playsinline url='https://www.youtube.com/watch?v=B0IZcD4DUEM' autoPlay loop muted />
                <div className='aurealestate-content'>
                    <h1>About Us</h1>
                </div>
            </div>
            <div className='aboutuspage'>
                <div className='breadcrum-div'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='aboutuspageline'></div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h2>AU Group</h2>
                            <p>A multifaceted real estate company, redefining the industry with innovation, transparency, and
                                customer-centricity. Your trusted partner in every real estate endeavor.
                            </p>
                            <div className='visionandmissiondiv'>
                                <div className='visiondiv'>
                                    <h3>Vision</h3>
                                    <p>AU Real Estate Group aspires to lead with transparency, provide service-oriented excellence,
                                        and instil pride of ownership in every client.
                                    </p>
                                </div>
                                <div className='missiondiv'>
                                    <h3>Mission</h3>
                                    <p>AU's mission is to consistently provide exceptional real estate products & services, guided by
                                        integrity, innovation, and a customer-first mindset, fostering trust and fulfilling dreams.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Aboutus