import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
const helmetContext = {};
function Metatag(props){
    return (
        <HelmetProvider context={helmetContext}>
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
                <link rel="canonical" href={props.link} />
            </Helmet>
        </div>
        </HelmetProvider>
    );
  }
export default Metatag