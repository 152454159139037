import React from 'react'

function Commingsoon() {
  return (
    <div className="commingsoon">
      <div className="content-txt-img">
        <div className="logonew">
          <img src="images/AU-Logo-main.png" alt=''/>
        </div>

        <div className="content">
          <h1>COMING SOON....</h1>

          <h6>We <strong>AU REAL ESTATE SERVICES PVT LTD</strong> is proud to announce that we will be coming soon with COMMERCIAL and HOUSING projects.</h6>

          <p>Please keep visiting our website for the latest updates.</p>
          <p>Contact: <a href="tel:9899992222">+91 9899992222</a></p>
        </div>
      </div>
    </div>
  )
}

export default Commingsoon