import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Servicecomingsoon() {
  return (
    <>
        <Header />
        <div className='advisorycomingsoondiv'>
            <img src='/websiteimages/Services.png' alt=''/>
            <h1>Coming Soon... </h1>
        </div>
        <Footer />
    </>
  )
}

export default Servicecomingsoon