import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Commingsoonpage() {
  return (
    <>
        <Header />
        <div className='aurealestate-innerbanner' data-aos="fade-up">
                {/* <video src='/websiteimages/banner-video.mp4' autoPlay loop muted /> */}
                <img src='/websiteimages/banner.jpg' alt='' />
                <div className='aurealestate-content'>
                    <h1>Coming Soon...</h1>
                </div>
            </div>
        <Footer />
    </>
  )
}

export default Commingsoonpage