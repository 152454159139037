import React from 'react'

function Footer() {
	return (
		<div className='footermain'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-4 col-md-5'>
						<div className='footermainleft'>
							<img src="/websiteimages/au-logo-black.png" alt='Au Real Estate' className='footerlogo' />
							<h5>AU Real Estate Services Pvt. Ltd</h5>
							<p>10, Bharat Bhawan, New Rajdhani Enclave, Vikas Marg, New Delhi 110092</p>
							<ul>
								<li>
									<a href="mailto:info@aurealestate.in">
										<img src="/websiteimages/Mail-icon.png" alt="Mail" /> info@aurealestate.in
									</a>
								</li>
								<li>
									<a href="tel:+919899992222">
										<img src="/websiteimages/Call-icon.png" alt="Call" /> +91 98999 92222
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className='col-lg-8 col-md-7'>
						<div className='alllogodiv'>
							<img src='/websiteimages/AU-Residences-logo.png' alt='Residences' loading='lazy'/>
							<img src='/websiteimages/AU-Commercial.png' alt='Commercial' loading='lazy'/>
							<img src='/websiteimages/AU-Advisory-logo.png' alt='Advisory' loading='lazy'/>
							<img src='/websiteimages/AU-Joint-Ventures-logo.png' alt='Joint Ventures' loading='lazy'/>
							<img src='/websiteimages/AU-Services-logo.png' alt='Services' loading='lazy'/>
						</div>
						<div className='row'>
							<div className='offset-lg-1 col-lg-3 col-md-4 col-6'>
								<h5>Quick Links</h5>
								<ul>
									<li><a href='/aboutus'>About Us</a></li>
									<li><a href='/contactus'>Contact Us</a></li>
									{/* <li><a href='/commingsoonpage'>Projects</a></li> */}
									<li><a href='/career'>Careers</a></li>
									<li><a href='/Blogs'>Blogs</a></li>
								</ul>
							</div>
							<div className='col-lg-4 col-md-4 col-6'>
								<h5>Other Links</h5>
								<ul>
									<li><a href='/privacy-policy'>Privacy Policy</a></li>
									<li><a href='/disclaimer'>Disclaimer</a></li>
									<li><a href='/terms'>Term and conditions</a></li>
								</ul>
							</div>
							<div className='col-lg-4 col-md-4'>
								<h5>Follow Us on</h5>
								<div className='social-links'>
									<ul>
										<li><a href='https://www.facebook.com/aurealestategroup/' target='_blank' rel="noreferrer"><img src='/websiteimages/facebook-edit.png' alt='facebook' /></a> </li>
										<li><a href='https://www.linkedin.com/company/aurealestategroup' target='_blank' rel="noreferrer"><img src='/websiteimages/linkedin-edit.png' alt='linkedin' /></a></li>
										<li><a href='https://www.instagram.com/aurealestategroup/' target='_blank' rel="noreferrer"><img src='/websiteimages/instagram-edit.png' alt='instagram' /></a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='footer-main-bottom'>
				<div className='container'>
					<p>Copyrights . All rights reserved. <span>Powered By <a href='https://www.weaddo.com/' target='_blank' rel="noreferrer">Weaddo</a></span></p>
				</div>
			</div>
		</div>
	)
}

export default Footer