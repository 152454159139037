import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Getintouch from './Getintouch'
import Metatag from './Metatag'

function Service() {
    return (
        <>
        <Metatag 
        link="service"
        title="Professional destination for construction Services | AU Real Estate"
        description="Transform your space with AU Real Estate's expert destination for construction services. Elevate aesthetics and functionality with our skilled decorators."
        />
            <Header />
            <Getintouch />
            <div className='aurealestate-innerbanner' data-aos="fade-up">
                <img src='/websiteimages/service-banner.jpg' alt='Service Banner' />
                <div className='aurealestate-content'>
                    <h1>Our Service</h1>
                </div>
            </div>
            <div className='service-main'>
                <div className='breadcrum-div'>
                    <div className='container'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Services</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='service-div'>
                    <div className='serviceline'></div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h2>Overview</h2>
                                <p>Introducing AU Real Estate Services, your one-stop destination for construction expertise, leasing 
                                    solutions, seamless property transactions, and top-notch facility management. Discover 
                                    comprehensive real estate services tailored to meet your every need.  <br/>
                                    AU Real Estate Services is your construction partner, offering a wide spectrum of expertise.
                                </p>
                                <img src='/websiteimages/service-img-top.jpg' alt='Overview' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='productlist-main'>
                    <div className='productlist-tab'>
                        <div className='container'>
                            <nav>
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#construction" type="button" role="tab" aria-controls="construction" aria-selected="true">Construction</button>
                                    <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#leasing" type="button" role="tab" aria-controls="leasing" aria-selected="false">Leasing</button>
                                    <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#buyandsell" type="button" role="tab" aria-controls="buyandsell" aria-selected="false">Buy & Sell</button>
                                    <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#facility" type="button" role="tab" aria-controls="facility" aria-selected="false">Facility Management</button>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="construction" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className='row'>
                                            <div className='col-sm-4'>
                                                <div className='productlisttab-div'>
                                                        <img src='/websiteimages/services-design-img.jpg' alt='Design & Build' />
                                                        <h4>Design & Build</h4>
                                                        <p>Our Design & Build services streamline your home building project from 
                                                            concept to completion, ensuring a cohesive, efficient, and cost-effective 
                                                            process.
                                                        </p>
                                                        {/* <h6>Learn More</h6> */}
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='productlisttab-div'>
                                                        <img src='/websiteimages/services-iterrior-img.jpg' alt='Interior' />
                                                        <h4>Interior</h4>
                                                        <p>Our Interior Design & Execution team crafts spaces that resonate with your 
                                                            vision, creating functional and aesthetically pleasing environments. 
                                                        </p>
                                                        {/* <h6>Learn More</h6> */}
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='productlisttab-div'>
                                                        <img src='/websiteimages/services-township.jpg' alt='Township Infra' />
                                                        <h4>Township Infra</h4>
                                                        <p>For Township Infrastructure Development, we're shaping vibrant communities, 
                                                            focusing on essential amenities and infrastructure that cater to modern living,
                                                             enhancing the quality of life for residents. Our commitment is to deliver 
                                                             construction solutions that transcend industry standards and exceed your 
                                                             expectations.
                                                        </p>
                                                        {/* <h6>Learn More</h6> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="leasing" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='leasingdiv'>
                                                    <div className='leasingdiv-left'>
                                                        <h4>Overview</h4>
                                                        <p>AU Real Estate Services takes pride in offering leasing solutions that 
                                                            cater to your specific needs, whether for residential or commercial 
                                                            properties. For Residential Leasing, we understand that your home is not 
                                                            just a space; it's your haven. Our extensive portfolio allows us to match 
                                                            you with the perfect property, whether it's a cosy apartment, a spacious 
                                                            house, or something in between. We work diligently to accommodate your 
                                                            unique lifestyle, preferences, and budget. 
                                                        </p>
                                                        <p>For Commercial Leasing, we recognize that your business's success often 
                                                            hinges on location and functionality. Our team of experts collaborates 
                                                            with you to identify properties that align with your industry, clientele, 
                                                            and growth strategy. We understand that commercial properties must be 
                                                            more than just spaces; they must be strategic assets. We ensure you find 
                                                            the ideal space to enhance your operations. 
                                                        </p>
                                                        <p>Our commitment to excellence extends to both residential and commercial 
                                                            leasing. We're dedicated to providing a seamless and tailored experience, 
                                                            ensuring your real estate journey is marked by satisfaction and success. 
                                                        </p>
                                                    </div>
                                                    <div className='leasingdiv-right'>
                                                        <img src='/websiteimages/servicetab-lease-img.jpg' alt='leasing' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="buyandsell" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div className='row'>
                                        <div className='col-lg-12'>
                                                <div className='leasingdiv'>
                                                    <div className='leasingdiv-left'>
                                                        <h4>Overview</h4>
                                                        <p>AU Real Estate Services Buy & Sell division is your partner in making 
                                                            strategic property transactions. 
                                                        </p>
                                                        <p>For Sellers: We bring your property to market with a comprehensive marketing 
                                                            strategy, leveraging our extensive network and expertise. Our goal is to 
                                                            secure the best deal possible for your asset. We guide you through the 
                                                            sales process, from setting the right price to negotiating offers and 
                                                            finalizing the transaction. 
                                                        </p>
                                                        <p>
                                                        For Buyers: We help you find the property that perfectly fits your criteria, 
                                                        whether you're looking for a new home or a commercial investment. Our in-depth 
                                                        knowledge of the market, combined with an understanding of your needs, ensures 
                                                        you see the properties that meet your goals. We negotiate on your behalf to get 
                                                        you the best value for your investment. 
                                                        </p>
                                                        <p>
                                                        At AU Real Estate Services, we understand that buying or selling a property is 
                                                        a significant financial decision. Our team of experts works diligently to 
                                                        simplify the process, reduce your stress, and optimize your returns. Your 
                                                        success is our success, and we're committed to delivering unparalleled service 
                                                        in every real estate transaction. 
                                                        </p>
                                                    </div>
                                                    <div className='leasingdiv-right'>
                                                        <img src='/websiteimages/servicetab-buysell-img.jpg' alt='buysell' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="facility" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div className='row'>
                                        <div className='col-lg-12'>
                                                <div className='leasingdiv'>
                                                    <div className='leasingdiv-left'>
                                                        <h4>Overview</h4>
                                                        <p>AU Real Estate Services offers comprehensive Facility Management with Annual Maintenance Contracts (AMC) for complex buildings. 
                                                        </p>
                                                        <p>Housekeeping Services: Our dedicated housekeeping team ensures that your property maintains the highest standard of cleanliness. From daily cleaning to specialized services, we keep your environment spotless and inviting.
                                                        </p>
                                                        <p>
                                                        Security Services: Safety is our top priority. Our security personnel are highly trained to protect your property and its occupants. We employ advanced security technology and maintain a strong presence to ensure a secure environment. 
                                                        </p>
                                                        <p>
                                                        HVAC (Heating, Ventilation, and Air Conditioning): We keep your HVAC systems in peak condition to ensure the comfort of the occupants. Regular maintenance and quick response to issues are central to our HVAC services. 
                                                        </p>
                                                        <p>
                                                        Plumbing Services: Our plumbing experts handle routine maintenance, repairs, and emergencies swiftly. We maintain a smooth water supply, drainage, and sanitation, ensuring a hassle-free environment. 
                                                        </p>
                                                        <p>
                                                        Electrical Services: Our electrical services include regular inspections, maintenance, and immediate response to electrical issues. We keep your property powered safely and efficiently. 
                                                        </p>
                                                        <p>
                                                        Our Facility Management services with Annual Maintenance Contracts are designed to provide a worry-free environment for property owners and residents. We are committed to enhancing the value of your property by ensuring it operates at its best, all year round. 
                                                        </p>
                                                    </div>
                                                    <div className='leasingdiv-right'>
                                                        <img src='/websiteimages/servicetab-facility-img.jpg' alt='facility' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='faq-main'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='faq-div'>
                                    <div className='faq-leftdiv'>
                                        <h3>Frequently Asked Questions</h3>
                                    </div>
                                    <div className='faq-rightdiv'>
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-related-map="map1" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Commitment
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Our commitment to delivery means adhering to timelines with unwavering dedication. <br />
                                                            Regarding specifications, we ensure every detail is met precisely. <br />
                                                            Quality is our uncompromising standard, guaranteeing excellence in every aspect of our
                                                            work</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-related-map="map2" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Customer Centricity
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Customer-centricity is the cornerstone of our approach, starting with personalized planning that aligns with clients' goals. <br />
                                                            Throughout the property's life, we offer support, transparency, and flexibility, ensuring their ownership experience is smooth, fulfilling, and tailored to their evolving needs.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-related-map="map3" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Transparency
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>Transparency is our guiding principle in every interaction.<br />
                                                            We openly share information, ensuring clarity, honesty, and trust. <br />
                                                            Whether in deals or day-to-day interactions, it fosters an environment of integrity, accountability, and strong, lasting partnerships</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    )
}

export default Service