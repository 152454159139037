import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Getintouch from './Getintouch'
import Metatag from './Metatag'

function Terms() {
    return (
        <>
        <Metatag 
        link="terms"
        title="AU Real Estate Terms: Understand Our Policies"
        description="Explore AU Real Estate's terms and conditions. Understand our policies regarding the use of our website and services for a seamless experience."
        />
            <Header />
            <Getintouch />
            {/* <div className='aurealestate-innerbanner' data-aos="fade-up">
                <img src='/websiteimages/news-media-banner.jpg' alt='' />
                <div className='aurealestate-content'>
                    <h1>Terms and Conditions</h1>
                </div>
            </div> */}
            <div className='otherdiv'>
                <div className='container'>
                    <h1>Terms and Conditions</h1>
                </div>
            </div>
            <div className='breadcrum-div'>
                <div className='container'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='privacy-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-10 col-lg-12'>
                            <p>Welcome to AU Real Estate Services Private Limited (“Website”). The contents of this website are
                                for general informational purposes only. By using or accessing this Website and/or the hyperlinks
                                embedded/ mentioned in the Website you agree to abide by the terms and conditions, as set forth
                                herein and as contained in the Website without any qualification or limitation. In the event you
                                do not accept/ agree to the terms of this Disclaimer, you are requested not to use the Website or
                                download materials from the Website. Every endeavor has been made to keep the information updated.
                                However, we make no representations or warranties of any kind, express or implied, about the
                                completeness, accuracy, reliability, suitability or availability with respect to the Website or
                                the information, products, services, or related graphics contained in the Website for any purpose.
                                The Website and all its content are provided on an “as is” and “as available” basis and is intended
                                for informational purposes only. No information given under this Website shall be treated as a warranty
                                or representation by us. Your use of the Website is solely at your own risk. This Website is for guidance
                                only. It does not constitute part of an offer or contract. The contents, including design and
                                specifications are subject to change without prior notice. Computer generated images are the artist's
                                impression and are an indicative of the actual designs. By using and/or accessing this Website, you,
                                the viewer/user acknowledge that the information including any material/ documents (such as brochures
                                and marketing collaterals) on this Website are solely for informational purposes and you have not
                                relied on this information for making any booking/ purchase/ intention to purchase/ invest in any
                                project(s) of AU Real Estate Services Private Limited and/or its affiliates. Nothing herein shall be
                                construed as any invitation, or offer for investment or deposit within the terms of the applicable law
                                (as amended from time to time) including without limitation SEBI Act, 1992, Companies Act 2013 etc.
                                and we shall not be liable for any consequence of any action taken by the viewer relying on such
                                material/ information on this Website. The particulars contained on the Website mention details of
                                Projects/ developments undertaken by AU Real Estate Services Private Limited and/or its affiliates.
                                The contents are being modified in terms of the stipulations/ recommendations under the Real Estate
                                Regulation Act, 2016 and Rules made thereunder (“RERA”) and accordingly may not be fully in line thereof
                                as of date. In no event shall AU Real Estate Services Private Limited, its affiliates, employees,
                                directors and/or officers be made liable for any claim as a result of any inaccuracies in the information
                                provided in this Website, though all efforts have to be made to ensure accuracy and under no
                                circumstances shall we be liable for any expense, loss or damage including, without limitation,
                                indirect or consequential loss or damage, or any expense, whatsoever arising from use, or loss of use,
                                of data, arising out of or in connection with the use of this Website.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Terms