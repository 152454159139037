import React from 'react'

function Footer() {
  return (
    <footer id="location">
				<div className="container">
					<div className="row">
						<div className="col-xl-4 col-md-4 col-12">
							<div className="footer-logo">
								<ul>
									<li>
										<a href='/'>
										<img src="images/au-logo-black.png" alt="AU Real Estate" />
										</a>
									</li>
									<li>
										<img src="images/aditya-logo.png" alt="Aditya" />
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-5 col-md-4 col-12">
							<h5>AU Real Estate Services Pvt. Ltd.</h5>
							<p>10, Bharat Bhawan, New Rajdhani Enclave, Vikas Marg, New Delhi 110092</p>
						</div>
						<div className="col-xl-3 col-md-4 col-12">
							<div className="contentdetail">
								<ul>
									<li><a href="mailto:marketing@aurealestate.in"><img src="images/Mail-icon.png" alt="Mail" /> marketing@aurealestate.in</a></li>
									<li><a href="tel:+917309999730"><img src="images/Call-icon.png" alt="Call" /> +91 7309999730</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-4 col-md-4 col-12">
							<div className="social-logo">
								<h5>Follow Us on</h5>
								<ul>
									<li>
										<a href='https://www.facebook.com/aurealestategroup/' target='_blank' rel='noreferrer'>
										<img src="images/facebook-edit.png" alt="facebook" />
										</a>
									</li>
									<li>
									<a href='https://www.linkedin.com/company/aurealestategroup' target='_blank' rel='noreferrer'>
										<img src="images/linkedin-edit.png" alt="linkedin" />
										</a>
									</li>
									{/* <li>
										<img src="images/twitter-icon.png" alt="" />
									</li> */}
									<li>
									<a href='https://www.instagram.com/aurealestategroup/' target='_blank' rel='noreferrer'>
										<img src="images/instagram-edit.png" alt="instagram" />
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-5 col-md-4 col-12">
							<h6>Site Address:</h6>
							<p>Commiercial Plot No-1, Sector-2, Aditya World City, NH-24, Ghaziabad, Uttar Pradesh 201002</p>
						</div>
						<div className="col-xl-3 col-md-4 col-12 reradiv">
							<h6>RERA Registration Number</h6>
							<p> <b>UPRERAPRJ516674</b> <br />
								<b><a href="https://www.up-rera.in/index" target='_blank' rel='noreferrer'>(www.up-rera.in)</a></b>
							</p>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="container">
						<div className="row">
							<div className="col-xl-10 col-md-9">
								<span>*The images shown above are for illustration purposes only and are not an exact representation of the product.</span>
							</div>
							<div className="col-xl-2 col-md-3">
								<span>Copyrights . All rights reserved.</span>
							</div>
						</div>
					</div>
				</div>
			</footer>
  )
}

export default Footer