import React from 'react';

export const BlogListDetails = {
    heading: 'Latest Blogs',
    BlogList: [
        {
            link: 'examining-indias-latest-real-estate-budget',
            image: '/websiteimages/blog-img1.jpg',
            title: "Empowering Homeownership: Examining India's Latest Real Estate Budget",
            desc: "Every new development in the real estate industry has the potential to cause financial and physical upheaval. The industry is currently buzzing with delight over the most recent budget proposals."
        },
        {
            link: 'exploring-2024-trends-in-real-estate-market-in-india',
            image: '/websiteimages/blog-img2-thumb.jpg',
            title: "Exploring 2024 Trends in Real Estate Market in India",
            desc: "The real estate industry is an important component of the Indian economy, and it has been changing and expanding recently. Real estate investment in India has grown to be a major economic force, driven by urbanization and rising incomes."
        },
        {
            link: 'key-considerations-for-purchasing-residential-plots-in-ghaziabad',
            image: '/websiteimages/blog-img3-thumb.jpg',
            title: "Key Considerations for Purchasing Residential Plots in Ghaziabad",
            desc: "Within the bustling National Capital Region (NCR), Ghaziabad is an attractive haven for those seeking a permanent residence. Tucked away in the heart of Uttar Pradesh and conveniently proximate to Delhi, the city's real estate sector thrives"
        },
        {
            link: 'exploring-essential-drivers-in-real-estate-growth-and-investment',
            image: '/websiteimages/blog-thumb-img5.jpg',
            title: "Exploring Essential Drivers in Real Estate Growth and Investment",
            desc: "Alright, let's talk real estate– the place where your dream home, maybe a stunning residential plot for sale, could be waiting to be found. The Indian real estate game is picking up after a bit of a slow phase."
        },
        {
            link: 'what-makes-investing-in-residential-property-in-ghaziabad-a-smart-choice',
            image: '/websiteimages/blog-img6-thumb.jpg',
            title: "What Makes Investing in Residential Property in Ghaziabad a Smart Choice?",
            desc: "Are you considering entering the real estate market? We've got some news for you, so hang on to your hat— buying a house in Ghaziabad might be the calculated move you've been looking for."
        },
        {
            link: 'wealth-building-101-a-guide-to-choosing-the-right-property-investment-company',
            image: '/websiteimages/blog-img7-thumb.png',
            title: "Wealth Building 101— A Guide to Choosing the Right Property Investment Company",
            desc: "Ever wondered what sets apart a remarkable real estate journey from an ordinary one? The answer lies in a single, pivotal choice— choosing the right property investment company."
        },
        {
            link: 'community-connectivity-the-impact-of-location-on-commercial-spaces',
            image: '/websiteimages/blog-img8-thumb.png',
            title: "Community Connectivity– The Impact of Location on Commercial Spaces",
            desc: "Investing in commercial space for sale is parallel to going on a strategic quest for financial success. It's a promising step filled with nuances, and to get through it successfully, one must master the art of deciphering the crucial factor."
        },
        {
            link: 'how-do-ghaziabads-residential-plots-offer-a-canvas-for-personalized-dream-homes',
            image: '/websiteimages/blog-img9-thumb.jpg',
            title: "How Do Ghaziabad's Residential Plots Offer A Canvas For Personalized Dream Homes?",
            desc: "In the vibrant city of Ghaziabad, a playground for dreamers awaits amidst the hustle and bustle: residential plots for sale. Picture this—a blank canvas where imagination knows no bounds, where every brushstroke of creativity shapes the contours of your personalized sanctuary."
        },
        {
            link: 'beyond-the-ordinary-6-features-that-define-a-lavish-home',
            image: '/websiteimages/blog-img10-thumb.jpg',
            title: "Beyond The Ordinary: 6 Features That Define A Lavish Home",
            desc: "A dream home will mean something different for each person. But in general, the features, including interiors, amenities, or facilities, define how opulent and exquisite a home is. Nowadays, many luxury houses in India are constructed with unique elements that give your ideal house a unique feel."
        },
        {
            link: 'how-to-choose-the-right-real-estate-project-in-ghaziabad',
            image: '/websiteimages/blog-img11-thumb.jpg',
            title: "How to Choose the Right Real Estate Project in Ghaziabad",
            desc: "Choosing the right real estate project is an important decision, especially in a growing area like Ghaziabad. With so many options, finding the best one can be tough. Here’s a simple guide to help you pick the right real estate projects in Ghaziabad."
        },
        {
            link: 'what-to-expect-from-a-leading-property-investment-company',
            image: '/websiteimages/blog-img12-thumb.jpg',
            title: "What to Expect from a Leading Property Investment Company?",
            desc: "When considering investing in real estate, partnering with a leading property investment company can make a big difference. These companies play a crucial role in guiding you through the complex world of property investment."
        },
        {
            link: 'how-a-consultant-turbocharges-your-home-buying-in-2024',
            image: '/websiteimages/blog-img13-thumb.jpg',
            title: "How a Consultant Turbocharges Your Home Buying in 2024",
            desc: "In the challenging landscape of the 2024 housing market, finding your dream home can be daunting. As you navigate this monumental task, the question arises– should you opt for real estate consulting services or go about it yourself"
        },
        {
            link: '7-advantages-of-real-estate-joint-ventures-in-india',
            image: '/websiteimages/blog-img14-thumb.jpg',
            title: "7 Advantages of Real Estate Joint Ventures In India",
            desc: "In the rapidly evolving field of real estate construction, builders and developers are always looking for ways to minimize risks and optimize profits. A real estate joint venture is one such approach."
        },
        {
            link: 'investing-in-luxury-aditya-world-city-nh-24s-top-projects',
            image: '/websiteimages/blog-img15-thumb.jpg',
            title: "Investing in Luxury: Aditya World City NH-24’s Top Projects",
            desc: "Aditya World City NH-24 is a standout in luxury living, offering some of the finest high-end projects in the area. Its excellent location and top-notch amenities make it a prime spot for investment in NCR."
        },
        {
            link: 'beyond-investment-the-cultural-and-social-impact-of-real-estate-projects-in-india',
            image: '/websiteimages/blog-img16-thumb.jpg',
            title: "Beyond Investment: The Cultural and Social Impact of Real Estate Projects in India",
            desc: "India is a canvas well embellished with urban landscapes reshaped by architectural marvels. Every structure from the lofty Mumbai skyscrapers to the palatial Jaipur mansions speaks about India’s diverse cultural heritage and exciting growth."
        },
        {
            link: 'are-government-initiatives-influencing-real-estate-investment-choices-in-india',
            image: '/websiteimages/blog-img17-thumb.jpg',
            title: "Are Government Initiatives Influencing Real Estate Investment Choices in India?eal Estate Investment in India | Government Initiatives Impact",
            desc: "Government initiatives play a crucial role in shaping the real estate investment in India, influencing investment choices and strategies. These initiatives can range from policy reforms to infrastructure development projects."
        },
        {
            link: 'plotting-the-dream-design-inspiration-for-residential-plots-in-ghaziabad',
            image: '/websiteimages/blog-img18-thumb.jpg',
            title: "Plotting the Dream - Design Inspiration for Residential Plots in Ghaziabad",
            desc: "Ready to have your dream house built? Residential plots in Ghaziabad are like canvases, where we paint them with our personal touch. Maybe you would prefer modern and sleek styles or a very traditional feel?"
        },
        {
            link: 'what-are-the-benefits-of-working-with-a-top-rated-property-investment-firm',
            image: '/websiteimages/blog-img19-thumb.jpg',
            title: "What are the Benefits of Working with a Top-Rated Property Investment Firm?",
            desc: "Navigating the intricate landscape of real estate investments requires expertise and strategic insight."
        },
        {
            link: 'discovering-the-best-deals-on-commercial-shops-for-sale-9-important-tips',
            image: '/websiteimages/blog-img20-thumb.jpg',
            title: "Discovering the Best Deals on Commercial Shops for Sale- 9 Important Tips",
            desc: "Scoring amazing deals on commercial real estate is the cornerstone of successful investment. At AU Real Estate, we follow a simple policy— offering only the best projects with the most potential for everyone involved."
        },
        {
            link: 'what-impact-does-the-right-living-space-have-on-family-well-being',
            image: '/websiteimages/blog-img21-thumb.jpg',
            title: "What Impact Does The Right Living Space Have On Family Well-Being",
            desc: "In Ghaziabad, real estate isn't just about acquiring land; it involves building a sanctuary from which families can flourish. Finding a dream home no longer revolves around bricks and mortar but instead securing an environment that will support the growth of family members."
        }
    ]
}